import { Component } from "react";
interface SocialMessage {
  link: string;
  name: string;
  class: string;
}
interface SocialProps {
  data: {
    social: SocialMessage[];
  };
}

export class Social extends Component<SocialProps> {
  render() {
    const { social } = this.props.data;
    return (
      <>
        {social &&
          social.map((social: any, index: number) => (
            <a
              key={index}
              href={social.link}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={social.name}
            >
              <i className={social.class_icon} />
            </a>
          ))}
      </>
    );
  }
}
