import { Component } from "react";
import { Link } from "react-router-dom";
import { Social } from "./Social";
import { ScrollToView } from "../tools/Helpers";
import SvgLogo from "./SvgLogo";

interface MenuMessage {
  url: string;
  title: string;
  attr_title: string;
  classes: any;
  target: string;
}
interface SocialMessage {
  link: string;
  name: string;
  class: string;
}
interface FooterProps {
  color?: string;
  esrb: string;
  data: {
    footer: MenuMessage[];
    social: SocialMessage[];
    isReady: boolean;
  };
}
export class GameFooter extends Component<FooterProps> {
  render() {
    const currentYear = new Date().getFullYear();
    const { footer, isReady } = this.props.data;
    return (
      <footer id="app-footer" className="container-fluid">
        <div className="d-flex justify-content-between">
          <div className="offset-esrb"></div>
          <div className="navbar-brand">
            <Link to="/">
              <SvgLogo
                fillColor={
                  this.props.color ? this.props.color : `rgb(255, 255, 255)`
                }
              />
            </Link>
          </div>
          <div className="offset-esrb">
            <img src={this.props.esrb} className="img-fluid" alt="" />
          </div>
        </div>
        <div className="social-block d-flex align-items-center justify-content-center">
          <Social data={this.props.data} />
        </div>
        <div className="footer-links d-flex flex-wrap justify-content-center">
          {isReady &&
            footer.map((footer: any, index: number) =>
              footer.classes && footer.classes[0] === "scroll" ? (
                <Link
                  key={index}
                  className="link"
                  to={footer.url}
                  onClick={() => ScrollToView(footer.post_name)}
                >
                  {footer.title}
                </Link>
              ) : (
                <Link key={index} className="link" to={footer.url}>
                  {footer.title}
                </Link>
              )
            )}
        </div>
        <div className="footer-legal">
          <span>©{currentYear} emptyvessel inc.</span>
        </div>
      </footer>
    );
  }
}
