import WPClass from "../tools/WPClass";

export const getPageContent = async (
  type: string,
  endpoints: {
    [key: string]: string;
  }
) => {
  const wpInstance = new WPClass();
  // Make Request
  let contentName = `content_${type}`;
  const storedData = sessionStorage.getItem(contentName);
  if (storedData) {
    return await JSON.parse(storedData);
  } else {
    try {
      const responseData = await wpInstance.makeRequestPromise(endpoints);
      sessionStorage.setItem(contentName, JSON.stringify(responseData));
      return responseData;
    } catch (error) {
      console.error(error);
      return null; // Return null or throw an error as needed
    }
  }
};
