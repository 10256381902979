import ScrollMagic from "scrollmagic";

interface ScrollAnimParams {
  element: string;
  trigger: number;
  duration: string;
  target?: string;
  offset?: number;
}

class ScrollAnimation {
  private ScrollController;

  constructor() {
    this.ScrollController = new ScrollMagic.Controller();
  }

  // Method to Handle all Scroll Animation
  ScrollAnim(json: ScrollAnimParams) {
    const triggerElement = document.querySelector(json.element);

    if (!triggerElement) {
      console.error(
        `ScrollAnimation: Element defined in option "triggerElement" was not found: ${json.element}`
      );
      return; // Exit the function if the element is not found
    }

    const Scrolls = new ScrollMagic.Scene({
      triggerElement: json.element,
      triggerHook: json.trigger,
      duration: json.duration,
    }).addTo(this.ScrollController);

    if (json.target) {
      var elements = document.querySelectorAll(json.target);
      Scrolls.on("enter", function (e) {
        for (var i = 0; i < elements.length; ++i) {
          var setAnimation =
            (elements[i] as HTMLElement).dataset.ease || "fadeInUp";
          (elements[i] as HTMLElement).classList.add(setAnimation);
        }
      });
      Scrolls.on("leave", function (e) {
        for (var i = 0; i < elements.length; ++i) {
          var setAnimation =
            (elements[i] as HTMLElement).dataset.ease || "fadeInUp";
          var setStatic = elements[i].classList.contains("repeat") ? 0 : 1;
          if (!setStatic) {
            elements[i].classList.remove(setAnimation);
          }
        }
      });
    }
    if (json.offset) {
      Scrolls.offset(json.offset);
    }
  }
}

export default ScrollAnimation;
