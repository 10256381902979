import { FC } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import TeamPage from "./pages/TeamPage";
import MediaPage from "./pages/MediaPage";
import DefectPage from "./pages/DefectPage";
import PrivacyPage from "./pages/PrivacyPage";
import SecurityPage from "./pages/SecurityPage";
import TermsPage from "./pages/TermsPage";
import Layout from "./Layout";
import "./styles/App.scss";

const App: FC = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about/" element={<HomePage />} />
          <Route path="/team/" element={<TeamPage />} />
          <Route path="/media/" element={<MediaPage />} />
          <Route path="/games/defect/" element={<DefectPage />} />
          <Route path="/security/" element={<SecurityPage />} />
          <Route path="/privacy-policy/" element={<PrivacyPage />} />
          <Route path="/terms-of-service/" element={<TermsPage />} />
          <Route path="/contact/" element={<HomePage />} />
          <Route path="*" element={<HomePage />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
