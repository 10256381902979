import { useEffect, useRef, useState, FC } from "react";
import { Header } from "../components/Header";
import { PreLoader } from "../components/PreLoader";
import { Footer } from "../components/Footer";
import imagesLoaded from "imagesloaded";
import ScrollAnimation from "../tools/ScrollAnimation";
import PageContextClass from "../tools/PageContextClass";
import { useLocation } from "react-router-dom";
import HeroDecal from "../assets/hero_decal.svg";
import TargetDecal from "../assets/target_decal.svg";

interface DataState {
  sharedData: any;
  pageData: any;
}

const PrivacyPage: FC = () => {
  const { pathname } = useLocation();
  const InitEffect = useRef(false);
  const [pageContent, setPageData] = useState<DataState>({
    sharedData: {},
    pageData: {},
  });

  useEffect(() => {
    const ScrollController = new ScrollAnimation();
    const Init = () => {
      window.scrollTo(0, 0);
      // Call the makeRequest method
      try {
        fetchData();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const fetchData = async () => {
      try {
        const pageContentClass = new PageContextClass(pathname);
        const responds = await pageContentClass.fetchData();
        if (responds && responds.sharedData && responds.pageData) {
          setPageData(responds);
          startProcess();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const startProcess = () => {
      const imgLoad = imagesLoaded("#root", { background: true });
      imgLoad.on("done", () => {
        // Handle Inview Area
        ScrollController.ScrollAnim({
          element: "#app-nav",
          duration: "100%",
          trigger: 0.7,
          target: "#app-nav .inview",
        }); // Header
        ScrollController.ScrollAnim({
          element: "#app-privacy",
          duration: "100%",
          trigger: 0.7,
          target: "#app-privacy .inview",
        });
      });
    };
    if (process.env.NODE_ENV === "development") {
      if (InitEffect.current) {
        const cleanup = Init();
        return cleanup;
      }
      return () => {
        InitEffect.current = true;
      };
    } else {
      Init();
    }
  }, [pathname]);
  return (
    <div id="app-pages">
      <div
        id="app-loader"
        className={`collapse ${pageContent.pageData.isReady ? "" : "show"}`}
      >
        <PreLoader />
      </div>
      <div
        id="app-privacy"
        className={`collapse fade ${
          pageContent.pageData.isReady ? "show" : ""
        }`}
      >
        <Header location={pathname} data={pageContent.sharedData} />
        <div className="draw-lines">
          <div
            className="line-overlay stroke-lines inview animated"
            data-ease="fadeIn"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="img-fluid"
              viewBox="0 0 448.94 448.94"
            >
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <line x1="0.4" y1="448.54" x2="448.54" y2="0.4" />
                </g>
              </g>
            </svg>
          </div>
          <div
            className="hero-underlay-line stroke-lines inview animated"
            data-ease="fadeIn"
          >
            <svg
              className="img-fluid"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 1278 1343"
            >
              <g>
                <defs>
                  <rect x="-100" y="-2.1" width="1378.6" height="1401.7" />
                </defs>
                <clipPath id="SVGID_2_">
                  <rect
                    x="-100.03"
                    y="-2.06"
                    width="1378.57"
                    height="1401.74"
                  />
                </clipPath>
                <g className="st0">
                  <polygon points="2.6,838.1 852.5,-18.1 1487.9,-18.1 810.1,667.6 810.1,1341.1 316,841.2" />
                  <polygon points="885.3,1339.8 885.3,708.1 1186,405.1 1815.2,405.1" />
                </g>
              </g>
            </svg>
          </div>
        </div>
        <div className="decal-overlay-top">
          <div className="inview animated delay-1" data-ease="fadeInLeft">
            <img
              src={HeroDecal}
              className="img-fluid"
              width="411"
              height="536"
              alt="Hero Decal Header"
            />
          </div>
        </div>
        <div
          className="decal-overlay inview animated delay-1"
          data-ease="fadeInLeft"
        >
          <img
            src={HeroDecal}
            className="img-fluid"
            width="411"
            height="536"
            alt="Hero Decal Header"
          />
        </div>
        <div className="decal-overlay-bottom">
          <img
            id="target-img"
            src={TargetDecal}
            className="img-fluid"
            width="54"
            height="48"
            alt="Down Decal"
          />
        </div>
        <section id="app-common-pages" className="container offset-top">
          {pageContent.pageData.isReady && (
            <>
              <div className="content-block">
                <h1 className="cake inview animated pb-4">
                  {pageContent.pageData.message.privacy[0].title.rendered}
                </h1>
                <div
                  className="inview animated delay-1"
                  dangerouslySetInnerHTML={{
                    __html:
                      pageContent.pageData.message.privacy[0].content.rendered,
                  }}
                />
              </div>
            </>
          )}
        </section>
        <Footer data={pageContent.sharedData} />
      </div>
    </div>
  );
};

export default PrivacyPage;
