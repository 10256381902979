import { FC } from "react";

interface SvgDefectLogoProps {
  fillColor: string;
}

const SvgDefectLogo: FC<SvgDefectLogoProps> = ({ fillColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 3509.59 641.16"
      className="img-fluid"
    >
      <polygon
        points="886.77 125.16 886.77 129.19 886.77 165.57 1313.19 165.57 1313.19 290.72 886.77 290.72 886.77 331.13 1313.19 331.13 1313.19 456.29 761.61 456.29 761.61 130.32 891.93 0 1313.19 0 1313.19 125.16 886.77 125.16"
        fill={fillColor}
      />
      <rect
        x="3053.64"
        y="165.57"
        width="135.23"
        height="290.73"
        fill={fillColor}
      />
      <polygon
        points="1477.23 165.57 1924.82 165.57 1799.67 290.72 1484.1 290.72 1484.1 509.12 1352.06 641.16 1352.06 0 2376.08 0 2501.25 125.16 1477.23 125.16 1477.23 165.57"
        fill={fillColor}
      />
      <polygon
        points="2081.29 290.72 2081.29 331.13 2503.28 331.13 2503.28 456.29 1956.12 456.29 1956.12 165.57 2503.28 165.57 2503.28 290.72 2081.29 290.72"
        fill={fillColor}
      />
      <polygon
        points="2897.18 331.13 3022.35 456.29 2539.76 456.29 2539.76 126.69 2666.46 0 3325.88 0 3451.04 125.16 2664.93 125.16 2664.93 331.13 2897.18 331.13"
        fill={fillColor}
      />
      <polygon
        points="723.42 130.74 723.42 456.29 0 456.29 258.89 197.41 258.89 331.13 591.38 331.13 591.38 125.16 116.4 125.16 241.56 0 592.68 0 723.42 130.74"
        fill={fillColor}
      />
    </svg>
  );
};

export default SvgDefectLogo;
